// src/App.js
import React, { useContext, useEffect } from "react";
import io from 'socket.io-client';
import getChave from '../request/Chave';
import AuthContext from '../context/AuthContext';
import Header from "./Header";
import Pagina from "./Pagina";
import moment from 'moment-timezone';
import Footer from "./Footer";
import Login from "./Login";
import Pagamento from "./Pagamento";
import { BASE_URL } from '../Api/config';

console.log(BASE_URL);


function Principal() {

  // Defina o fuso horário para Brasília
  moment.tz.setDefault('America/Sao_Paulo');

  const data = moment(); // Crie uma instância moment válida
  // Formate a data e o horário separadamente
  const formatoData = 'DD-MM-YYYY'; // Formato para a data
  const formatoHorario = 'HH:mm:ss'; // Use o formato para pegar somente o horário


  const dataFormatada = data.format(formatoData)
  const horarioFormatado = data.format(formatoHorario);

  const horarioBrasilia = `${dataFormatada}--${horarioFormatado}`
  let idUser = localStorage.getItem('id');
  let ipUser = localStorage.getItem('ip');

  const { setSocket, requestSaveIp, ip, socket, deviceInfo, telaPagar, setTelaPagar, setLoad, setMsgError, setContetAlert, telaClient, setChavePix, setServicos, setTelaClient, handleDeviceInfo, setCopiaEcola, setBase64 } = useContext(AuthContext);

  const enviarIp = (id, ip, deviceInfo, horarioBrasilia) => {
    if (socket) {
      console.log('enviar ip', id, ip, deviceInfo, horarioBrasilia);

      socket.emit('enviarIpDevice', { id, ip, deviceInfo, horarioBrasilia });
    }
  };

  const getPix = async () => {
    const result = await getChave();
    setChavePix(result);
  }

  useEffect(() => {
    // const newSocket = io('https://api-storm.portal-prd.website');
    const newSocket = io(BASE_URL);

    newSocket.on('connect', () => {
      // console.log(`Conectado. ID: ${newSocket.id}`);
      localStorage.setItem('id', newSocket.id);
      // setLoad(false);
    });

    newSocket.on('resultRequest', (id, result) => {
      console.log('Dados recebidos:', id, result);

      if (result.erro) {
        setLoad(false);
        return alert(result.erro);
      }

      if(result && result === "Cliente ou Endereço não encontrados.") {
        setLoad(false);
        return alert("Cliente ou Endereço não encontrados.")
      }

      if(result && result === "Falha na verificação do token") {
        setLoad(false);
        return alert("Tente novamente!")
      }

      if (result) {
        // const servicosNaoPagos = result.filter(servico => servico.statusPagamento === "Vencida");
        setServicos([result]);
        setLoad(false);
        setTelaClient(true);
      }

    });

    setSocket(newSocket);
    requestSaveIp();
    getPix();
    handleDeviceInfo();

    return () => {
      // newSocket.disconnect();
    };
  }, []);

  useEffect(() => {
    enviarIp(idUser, ipUser || ip, deviceInfo, horarioBrasilia)
  }, [ip, deviceInfo, idUser]);

  return (
    <div>
      <Header />
      {!telaClient && <Login />}
      {telaClient && <Pagina />}
      {/* {telaPagar &&  <Pagamento /> } */}
      {/* <Footer /> */}
    </div>
  );
}

export default Principal;
