import React, { useContext, useEffect, useState, useRef } from 'react';
import AuthContext from '../context/AuthContext';
import "../Styles/Footer.css"; // Importe o arquivo CSS
import menu from "../imagens/hamburg.png";
import home from "../imagens/home.png";
import back from "../imagens/back.png";

function Footer() {

  const { setCopySuccess, setTelaClient, setTelaPagar, setSelectedDebits } = useContext(AuthContext);

  const fechaTelaPagamento = () => {
    // setTelaClient(true);
    setSelectedDebits([]);
    setCopySuccess('');
    setTelaPagar(false);
  };


  return (
    <div className="footer">
      <div className="footer-buttons">
        {/* <div className="footer-button">
          <img src={menu} alt="menu" />
          Menu
        </div>
        <div className="footer-button">
          <img src={home} alt="menu" />
          Home
        </div>
        <div className="footer-button" onClick={() => fechaTelaPagamento()}>
          <img src={back} alt="menu" />
          Voltar
        </div> */}
      </div>
      <div className="footer-version">Guia do Cliente© COMGÁS 2024. Todos os direitos reservados.</div>
    </div>
  );
}

export default Footer;
