import React from "react";
import "../Styles/Header.css";
import logoSaldao from "../imagens/logo-saldao.png";

function Header() {
  return (
    <header>
      <div className="menu">
        <div className="divLogoSaldao">
          <img src={logoSaldao} alt="logo-" className="logoSaldao" />
        </div>
        <div className="divspans">
          <span className="span1">FIQUE EM DIA COM AS SUAS CONTAS!</span>
          <span className="span2">até 50% de desconto, sem juros e sem multas!</span>
        </div>
      </div>
      <div className="submenu">
        <div className="divoptions">
          <div className="option1">
            <div className="logoComgas">

            </div>
            <div className="virtal">
              virtual
            </div>
          </div>

          <div className="option2">
            <div className="ligarReligar">
              <div>
                Ligar/Religar o gás
              </div>
            </div>
          </div>

          <div className="option2">
            <div className="ligarReligar">
              <div>
                Loja de Serviços
              </div>
            </div>
          </div>

          <div className="fazerLogin">
            <div>
              <div>
                Fazer Login
              </div>
            </div>
          </div>

        </div>
      </div>
      <div className="nave">
        <div className="subNave">
          <div className="childNave">
            MINHA CONTA
          </div>
          <div className="childNave">
            RELIGAR O GÁS
          </div>
          <div className="childNave">
            MEUS PAGAMENTOS
          </div>
          <div className="childNave">
            SOLICITAÇÕES
          </div>
          <div className="childNave">
            SERVIÇOS
          </div>
          <div className="childNave red">
            EMERGÊNCIA
          </div>
          <div className="childNave white">
            O quê está procurando?
          </div>
        </div>

      </div>
    </header>
  );
}

export default Header;
